@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

:root {
    --main-color: #969696;
    --title-color: #414141;
    --link-color: #0066FF;
    --background: #5251C4;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    max-width: 100%;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

input,
button,
textarea,
select {
    font: inherit;
}

.format-type {
    background-color: transparent;
    border: none;
    color: inherit;
}

@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

html,
body {
    height: 100%;
    width: 100%;
}

body {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-weight: 400;
    background: #fff;
    color: var(--main-color);
    height: 100%;
    width: 100%;
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    padding-top: 132px;
}

.title {
    color: var(--title-color);
    font-size: 38px;
    font-weight: 700;
}

.title-xs {
    color: var(--title-color);
    font-size: 28px;
    font-weight: 700;
}

header {
    padding: 18px 0px 11px;
    color: var(--title-color);
    background: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(15px);
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

// select {
//     appearance: none;
//     text-align-last: center;
//     padding-right: 20px;
//     padding-left: 10px;
// }
.bttn-wrapper {
    margin: 0 auto;
}
.btn.btn-select {
    padding: 2px 5px;
}

::selection {
    color: white;
    background: #ff7675;
}

::-webkit-selection {
    color: white;
    background: #ff7675;
}

::-moz-selection {
    color: white;
    background: #ff7675;
}

.container {
    width: calc(960px + 32vw);
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

p {
    margin: 0;
}

.hide {
    display: none !important;
}

.ads-top {
    background: #F0F0F0;
    border-bottom: 1px solid #D9D9D9;
    height:50px;
    overflow: hidden;
    white-space: nowrap;
}

.ads-top ul{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.ads-top ul li {
    padding-left: calc(10px + 0.8vw);
    padding-right: calc(10px + 0.8vw);
}

.ads-top ul a {
    color: var(--link-color);
    text-decoration: underline;
    font-size: 18px;
}


.header-logo div:first-child {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-size: 22px;
}

.header-logo span {
    display: inline-block;
    padding-left: 11px;
}

.sticky-header {
    position: fixed;
    top: 0;
    z-index: 999;
    left: 0;
    width: 100%;
}

.ads-top__title {
    padding-left: 112px;
}

.subtitle {
    font-size: 20px;
}

.title-wrapper {
    padding-top: calc(40px + 1.5vw);
    padding-bottom: calc(40px + 1.5vw);
}

.column-center {
    width: 800px;
}

.title-wrapper .container {
    width: 830px;
    max-width: 100%;
}

.ad-item {
    margin-bottom: 300px;
}

.pronto {
    position: relative;
    border-radius: 30px;
    width: 100%;
    min-height: 500px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // overflow: hidden;
    margin-bottom: 100px;
    border: 1px #8C8C8C dashed;
    &.fileover {
        border: 1px #ff7675 dashed;
    }
}

.pronto > div {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.pronto::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // background-image: url("/svg/rectangle1.svg");
    // background-size: 100% 100%;
}


.drop-zone {
    font-size: 22px;
    font-size: #8C8C8C;
}

.drop-zone img {
    margin-bottom: 34px;
    width: 135px;
    height: 135px;
}

.separate-line {
    font-size: 22px;
    color: #2E2E2E;
    position: relative;
    width: 461px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 57px;
}

.separate-line::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    background: #000;
    top: 50%;
    z-index: 0;
}

.separate-line span {
    position: relative;
    z-index: 1;
    width: 61px;
    background: #fff;
    display: inline-block;
}

.clickableArea {
    cursor: pointer;
}

.secured-row {
    position: relative;
    justify-content: center;
    width: 100%;
    margin-top: 20px !important;
    div {
        padding: 2px 10px;
    }
    img {
        padding-right:10px;
        position: relative;
        top: 2px;
    }
    .secured-popup {
        position: absolute;
        top: 25px;
        right: calc(50% - 140px);
        width: 280px;
        height: 140px;
        font-size: 15px;
        color: #686868;
        padding: 37px 33px 20px 33px;
        text-align: left;
        background-image: url("/svg/popup.svg");
        background-repeat: no-repeat;
        background-color: white;
    }
}

.btn {
    background: linear-gradient(130deg, #7772F1 14.51%, #F52F6E 50%);
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 59px;
    padding: 16px 50px;
    border: 0;
    width: 259px;
    max-width: 100%;
    cursor: pointer;
    transition: all 0.3s;
}

.btn-grey {
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 59px;
    width: 200px;
    padding: 16px 50px;
    border: 0;
    background-color: #F3F3F3;
    color: #8C8C8C;
    position: absolute;
    bottom: 40px;
    right: 172px;
    cursor: pointer;
}

.disabled {
    background-image: none;
    background-color: #f8f9fa;
    color: rgba($color: #000000, $alpha: 0.2);
    // box-shadow: none;
    pointer-events: none;
  }

.btn-format {
    position: absolute;
    height: 59px;
    width: 200px;
    bottom: 40px;
    left: 172px;
    cursor: pointer;
}

.file {
    height: 58px;
    background: #F3F3F3;
    border-radius: 15px;
    margin-bottom: 10px;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: left;
    line-height: 58px;
    margin: 10px !important;
    .file-counter {
        padding: 0px 10px 0px 20px;
    }
    .file-name {
        width: 640px;
        overflow: hidden;
        height: 58px;
    }
}

.file-garbage {
    float: right;
    margin-top: 14px;
    line-height: 58px;
    text-align: center;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-image: url("/svg/garbage.svg");
}
.file-garbage:hover {
    background-image: url("/svg/garbage-hover.svg");
}

.format-types {
    font-weight: 700;
    font-size: 16px;
    color: black;
    border-radius: 10px;
}

.select-items div, .select-selected {
    border-radius: 50px;
    background-color: #000;
}

.select-items div,.select-selected {
    background-color: blue;
}

.files-wrapper {
    height: 263px;
    overflow-y: auto;
    overflow-x: hidden;
}

.file #text {
    padding-left: 15px;
}

.btn-add {
    height: 60px;
    line-height: 60px;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    width: 100%;
    border: 1px dashed #8C8C8C;
    border-radius: 15px;
    font-size: 22px;
    color: #1B1B1B;
}

.btn-add:hover {
    background-color: #EBEBEB;
}

.btn:hover {
    opacity: 0.9;
}

.btn span {
    display: inline-block;
    margin-right: 10px;
}

.btn-transparent {
    padding: 8px 40px;
    font-size: 20px;
    font-weight: 700;
    border: none;
    outline: none;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    background: linear-gradient(130deg, #7772F1 14.51%, #F52F6E 50%);
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}
.btn-transparent::before {
    content: "";
    position: absolute;
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 3px;
    border-radius: 9px;
    background-color: white;
    z-index: -1;
    transition: 200ms
}
.btn-transparent::after {
    content: attr(data);
    background: linear-gradient(130deg, #7772F1 14.51%, #F52F6E 50%);
    -webkit-background-clip: text;
    color: transparent;
    transition: 200ms;
}
.btn-transparent:hover::before {
    background-color: #EBEBEB;
}
.button-wrapper {
    display: flex;
}
   
.button-wrapper .btn {
    width: 120px;
    height: 48px;
    margin: 0 10px;
    max-width: calc(100% - 20px);
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.pronto-footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pronto-footer p {
    margin-left: 10px;
}

.pronto-upload {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.p-2 {
    padding-top: 2vw;
    padding-bottom: 2vw;
}

.m-auto {
    margin: auto;
}

.pronto__converting .title {
    color: #F52F6E;
}

.pronto__converting img {
    width: 100px;
}

.pronto__converting a {
    font-size: 18px;
    color: var(--title-color);
}

.pronto__cancel, .pronto__unvalid {
    align-items: center;
    justify-content: center;
}

.pronto__done {
    align-items: center;
}

.pronto-done-text {
    padding: 30px 0;
    color: #000;
    display: inline-block;
}

.pronto__done .try-again {
    color: #F52F6E;
    padding-left: 3px;
    cursor: pointer;
}

.pronto__cancel p {
    font-size: 18px;
    margin-top: 55px;
    margin-bottom: 50px;
    color: black;
}

.pronto__done img {
    width: 200px;
}

.pronto__done .title {
    font-size: 36px;
    margin-top: 0px;
    
    line-height: 43px;
}

.pronto__unvalid p {
    margin-top: 37px;
    margin-bottom: 79px;
}

.hero {
    padding-bottom: calc(40px + 2.2vw);
}

.steps {
    padding-top: calc(40px + 2vw);
}

.steps .column-center .row{
    margin-bottom: calc(20px + 4vw);
}

.about-title {
    font-size: 20px;
    color: #011837;
    margin-bottom: 16px;
}

.about {
    padding-top: calc(20px + 1.5vw);
    padding-bottom: calc(40px + 1.5vw);
}

.about-list {
    padding-top: calc(20px + 4.2vw);
}

.about-img {
    min-height: 80px;
}

.about-list > div {
    padding-left: calc(10px + 1.2vw);
    padding-right: calc(10px + 1.2vw);
}

footer {
    background: #F2F2F2;
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
}

footer ul {
    display: flex;
    flex-wrap: wrap;
}

footer ul li {
    padding-right: calc(10px + 2vw);
}

@media (min-width: 1400px) {
    .ads-top ul {
        padding-right: 13vw;
    }
}

@media (max-width: 1350px) {
    .file .file-name {
        width: 340px;
    }
    .title-wrapper .container {
        width: 610px;
    }
    .column-center {
        width: 500px;
    }
    // .pronto::after {
    //     background-image: url("/svg/rectangle2.svg");
    // }

    .about-list > div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .btn-format {
        width: 180px;
        position: absolute;
        left: 95px;
        font-size: 16px;
    }

    .btn-grey {
        width: 180px;
        position: absolute;
        right: 95px;
        font-size: 16px;
    }
}

@media (max-width: 1110px) {
    .title-wrapper .container {
        width: 530px;
    }
    .column-center {
        width: 500px;
    }
    // .pronto::after {
    //     background-image: url("/svg/rectangle3.svg");
    // }
}

@media (max-width: 1050px) {
    .column-ads {
        display: none;
    }

    .column-list {
        justify-content: center;
    }

    .row {
        flex-wrap: wrap;
    }

    .hero .row {
        margin: 0;
    }

    .pronto::after {
        background-image: none;
        border: 1px dashed #ccc;
        border-radius: 30px;
    }
}

@media screen and (max-width: 800px) and (max-height: 600px),
        screen and (max-width: 800px) {
    .file .file-name {
        width: 74%;
    }
    .mobile-hidden {
        display: none !important;
    }

    .column-list {
        justify-content: center;
    }
}
